'use client';

import {
	mdiMinusCircle,
	mdiPlusCircleMultipleOutline,
	mdiPlusCircleOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { motion, useReducedMotion } from 'motion/react';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface FilterItem {
	id: string;
	values?: string[];
	title: string;
	colors: {
		bg: string;
		text: string;
	};
}

const Filter = ({
	urlName,
	displayName,
	items,
	messages,
	className,
}: {
	urlName: string;
	displayName: string;
	items: FilterItem[];
	messages?: {
		whileFiltering?: string;
	};
	className?: string;
}) => {
	// Get value from url
	const searchParams = useSearchParams();
	const [selectedItems, setSelectedItems] = useState<FilterItem['id'][]>(
		searchParams
			?.get(urlName)
			?.split(',')
			.filter((id) => items.some((item) => item.id === id)) ?? [],
	);

	// Update the url when the selectedItems change, or delete the url parameter if no items are selected
	useEffect(() => {
		const url = new URL(window.location.href);
		if (selectedItems.length > 0) {
			url.searchParams.set(urlName, selectedItems.join(','));
		} else {
			url.searchParams.delete(urlName);
		}
		window.history.replaceState({}, '', url.toString());
	}, [selectedItems]);

	// Toggle item
	const toggleItem = (id: string, isChecked: boolean) => {
		if (isChecked) {
			setSelectedItems([...selectedItems, id]);
		} else {
			setSelectedItems(selectedItems.filter((item) => item !== id));
		}
	};

	return (
		<div
			className={twMerge(
				'shadow-border-bottom-md relative flex flex-col overflow-hidden rounded-3xl bg-primary-100/50 shadow-sm after:rounded-3xl dark:bg-slate-950',
				className,
			)}
		>
			<fieldset className="flex grow flex-col">
				<legend className="-mb-1 flex grow-0 flex-wrap items-center gap-1 ps-4 pt-2 text-lg font-bold">
					<Icon
						path={mdiPlusCircleMultipleOutline}
						aria-hidden
						size={0.8}
					/>
					{displayName}
				</legend>
				<div className="flex grow flex-col">
					<ul
						className="flex grow flex-wrap content-start items-start gap-2 p-2"
						aria-label={`Ongeselecteerd van ${displayName}`}
					>
						{items
							.filter((item) => !selectedItems.includes(item.id))
							.map((item) => (
								<FilterButton
									key={item.id}
									id={item.id}
									label={item.title}
									colors={item.colors}
									isChecked={selectedItems.includes(item.id)}
									setIsChecked={(isChecked) =>
										toggleItem(item.id, isChecked)
									}
								/>
							))}
					</ul>
					{selectedItems.length > 0 && (
						<div className="flex grow-0 flex-col bg-primary-200/20 p-2 shadow-inner transition-all dark:bg-primary/20">
							<motion.ul
								className="flex grow-0 flex-wrap content-start items-start gap-2"
								aria-label={`Geselecteerd van ${displayName}`}
								initial={{
									opacity: 0,
								}}
								animate={{
									opacity: 1,
								}}
							>
								{items
									.filter((item) =>
										selectedItems.includes(item.id),
									)
									.map((item) => (
										<FilterButton
											key={item.id}
											id={item.id}
											label={item.title}
											colors={item.colors}
											isChecked={selectedItems.includes(
												item.id,
											)}
											setIsChecked={(isChecked) =>
												toggleItem(item.id, isChecked)
											}
										/>
									))}
							</motion.ul>
							{!!messages?.whileFiltering && (
								<p
									role="status"
									className="p-2 pb-1 font-semibold"
								>
									{messages.whileFiltering}
								</p>
							)}
						</div>
					)}
				</div>
			</fieldset>
		</div>
	);
};

const FilterButton = ({
	id,
	label,
	colors,
	isChecked,
	setIsChecked,
}: {
	id: string;
	label: string;
	colors: FilterItem['colors'];
	isChecked: boolean;
	setIsChecked: (isChecked: boolean) => void;
}) => {
	const reduceMotion = useReducedMotion();

	return (
		<motion.li
			layoutId={reduceMotion ? undefined : `filter-${id}`}
			className="flex shrink-0 items-center gap-2"
		>
			<button
				type="button"
				aria-pressed={isChecked}
				onClick={() => setIsChecked(!isChecked)}
				title={`${label} filter ${isChecked ? 'verwijderen' : 'toevoegen'}`}
				className={`shadow-border-bottom-sm flex items-center gap-1 rounded-full px-3 py-1 after:rounded-full ${
					colors.bg
				} ${colors.text} ${
					isChecked ? `font-bold shadow-sm` : `font-semibold`
				} transition-all hover:bg-opacity-50`}
			>
				<Icon
					path={isChecked ? mdiMinusCircle : mdiPlusCircleOutline}
					size={1}
					className="-ml-2 shrink-0"
					aria-hidden
				/>
				{label}
				{isChecked && <p className="sr-only">(geselecteerd)</p>}
			</button>
		</motion.li>
	);
};

export default Filter;
